import React, { useEffect } from 'react';
import Main from '../components/pages/Main';
import OpenCompany from '../components/pages/OpenCompany';

const AbrirEmpresa = () => {  
  return (
    <Main page="abrir-empresa">
      <OpenCompany />
    </Main>
  );
}

export default AbrirEmpresa;
