import {
  Box,
  FormHelperText,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import InputWrap from '../atoms/InputWrap'
import PasswordTextField from '../atoms/PasswordTextField'
import StyledButton from '../atoms/StyledButton'
import {
  EmailValidation,
  LastNameValidation,
  LengthValidation,
  NameValidation,
  ObjectValidation,
  PasswordValidation,
  PhoneValidation,
} from '../functions/FormValidation'
import { SegmentOptions } from '../functions/Options'
import MaskedTextField from '../molecules/MaskedTextField'
import AutocompleteCityUF from './AutocompleteCityUF'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '25px',

    '& .labels': {
      color: `${theme.palette.midGrey.main} !important`,
    },
    '& .MuiTypography-button': {
      color: theme.palette.info.main,
    },
    '& .MuiTypography-h6': {
      marginBottom: '25px',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginBottom: '20px',
    },

    '@media screen and (max-width: 959px)': {
      padding: '20px',
    },
  },
  buttonWrap: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
}))

const OpenCompanyForm = ({
  name,
  isLoading,
  setIsLoading,
  data,
  setData,
  purpleFriday,
}) => {
  const classes = useStyles()
  const [error, setError] = useState({})

  const validateFields = () =>
    ObjectValidation(data, 8) &&
    LengthValidation(data) &&
    NameValidation(data) &&
    LastNameValidation(data) &&
    PhoneValidation(data) &&
    EmailValidation(data) &&
    PasswordValidation(data)
  // && TermsOfUseValidation(data)

  const isFormValid = () => {
    try {
      return validateFields()
    } catch (e) {
      return false
    }
  }

  const validateForm = () => {
    try {
      if (validateFields()) {
        setError({})
      }
    } catch (e) {
      setError({ field: e.fileName, message: e.message })
      return false
    }
    return true
  }

  const sendDataToHubspot = async () => {
    const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID
    const formGuid = process.env.GATSBY_HUBSPOT_ABRIR_EMPRESA_FORM_GUID
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`

    const dataHubspot = {
      fields: [
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'firstname',
          value: data.name,
        },
        {
          name: 'lastname',
          value: data.lastName,
        },
        {
          name: 'segmento_atuacao',
          value: data.segment,
        },
        {
          name: 'phone',
          value: data.phone,
        },
        {
          name: 'cidade_estado',
          value: `${data.cityName} - ${data.state}`,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    }

    try {
      const cloudfare = await axios.get(
        'https://www.cloudflare.com/cdn-cgi/trace'
      )
      if (cloudfare.data) {
        const ipInfo = cloudfare.data.split('\n')[2]
        if (ipInfo.indexOf('ip=') > -1) {
          dataHubspot.context.ipAddress = ipInfo.replace('ip=', '')
        }
      }

      await axios.post(url, dataHubspot, {
        headers: {
          'Content-Type': 'application/json', // Triggers preflight
        },
      })
    } catch (err) {
      setError({
        message: 'Desculpe, ocorreu um erro. Tente novamente mais tarde!',
      })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (validateForm()) {
      setIsLoading(true)
      try {
        const formData = {
          email: data.email,
          firstname: data.name,
          lastname: data.lastName,
          segment: data.segment,
          phone: data.phone,
          cityState: `${data.cityName} - ${data.state}`,
        }

        await sendDataToHubspot()

        if (data.segment === 'Comércio' || data.segment === 'Indústria') {
          setError({
            message: 'Não atendemos esse segmento de atuação no momento.',
          })
          return
        }

        await axios.post(
          `${process.env.GATSBY_ABRIR_EMPRESA_BASE_URL}/signup`,
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        )

        window.location.href = `${process.env.GATSBY_ABRIR_EMPRESA_BASE_URL}/login`
      } catch (error) {
        setError({
          message: 'Sorry, something went wrong. Please try again later.',
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  const submitEnter = ({ key }) => {
    if (key === 'Enter') {
      handleSubmit()
    }
  }

  const changeData = (value, field) => {
    setData(prevData => ({ ...prevData, [field]: value }))
    setError({})
  }

  const handleCityUFChange = value => {
    if (value) {
      setData({
        ...data,
        state: value.stateInitial,
        city: value.code,
        cityName: value.name,
      })
    } else {
      setData({
        ...data,
        state: null,
        city: null,
        cityName: null,
      })
    }
    setError({})
  }

  return (
    <div className={classes.root}>
      <form
        onSubmit={handleSubmit}
        name={name}
        className={process.env.GATSBY_CLASS_CADASTRO_ABRIR_EMPRESA}
      >
        <Typography variant="button" className="labels">
          Sobre você
        </Typography>
        <InputWrap>
          <TextField
            label="Nome"
            name="name"
            value={data.name}
            onChange={e => changeData(e.target.value, 'name')}
            error={error.field === 'name'}
            disabled={isLoading}
          />
          <TextField
            label="Sobrenome"
            name="lastName"
            value={data.lastName}
            onChange={e => changeData(e.target.value, 'lastName')}
            error={error.field === 'lastName'}
            disabled={isLoading}
          />
          <MaskedTextField
            onChange={e => changeData(e.target.value, 'phone')}
            mask="+55 (99) 99999-9999"
            textFieldProps={{
              label: 'Telefone',
              name: 'phone',
              type: 'tel',
              value: data.phone,
              floating: true,
              error: error.field === 'phone',
            }}
            InputProps={{
              disabled: isLoading,
            }}
          />
        </InputWrap>

        <Typography variant="button" className="labels">
          Sobre sua nova empresa
        </Typography>
        <InputWrap>
          <TextField
            label="Qual segmento de atuação?"
            name="segmento_atuacao"
            value={data.segment}
            onChange={e => changeData(e.target.value, 'segment')}
            disabled={isLoading}
            select
          >
            {SegmentOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <AutocompleteCityUF
            onChange={value => handleCityUFChange(value)}
            value={{
              stateInitial: data.state,
              code: data.city,
              name: data.cityName,
            }}
            disabled={isLoading}
          />
        </InputWrap>

        <Typography variant="button" className="labels">
          Cadastro
        </Typography>
        <InputWrap>
          <TextField
            label="E-mail"
            name="email"
            value={data.email}
            onChange={e => changeData(e.target.value, 'email')}
            error={error.field === 'email'}
            disabled={isLoading}
          />
          <PasswordTextField
            label="Senha"
            name="password"
            value={data.password}
            onChange={e => changeData(e.target.value, 'password')}
            error={error.field === 'password'}
            disabled={isLoading}
          />
        </InputWrap>

        {/* <CheckBoxTermsOfUse
          checked={data.acceptTermsCheck}
          onChange={(value) => changeData(value, 'acceptTermsCheck')}
          color="info"
          disabled={isLoading}
        /> */}

        <FormHelperText>{error.message}</FormHelperText>

        <Box onClick={validateForm} className={classes.buttonWrap}>
          <StyledButton
            text="Criar conta"
            type="submit"
            onClick={handleSubmit}
            disabled={!isFormValid() || isLoading}
            variant={purpleFriday ? 'purpleFriday' : 'contained'}
            color="secondary"
            width="100%"
          />
        </Box>
      </form>
    </div>
  )
}

OpenCompanyForm.propTypes = {
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  purpleFriday: PropTypes.bool.isRequired,
}

export default OpenCompanyForm
