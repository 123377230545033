import React, { useState } from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import Spacer from '../atoms/Spacer';
import GlazedEmoji from '../atoms/GlazedEmoji';
import StyledButton from '../atoms/StyledButton';
import FormCard from '../atoms/FormCard';
import HeroBackground from '../molecules/HeroBackground';
import OpenCompanyForm from '../organisms/OpenCompanyForm';
import CreateAccountDrawer from './CreateAccountDrawer';
import EmojiHand from '../../static/images/emoji-mao-apontando-direita.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    '& .title strong': {
      color: theme.palette.info.highlight,
    },

    '& .span': {
      color: `${theme.palette.info.light} !important`,
    }
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    width: '75%',
    gap: '20px',

    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  },
  button: {
    display: 'none',
    maxWidth: '85%',

    '@media screen and (max-width: 959px)': {
      display: 'block',
    },
  },
  form: {
    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  }
}));

const OpenCompanyHero = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [createAccountOpen, setCreateAccountOpen] = useState(false);
  const [data, setData] = useState({});

  return (
    <HeroBackground page="abrir-empresa">
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={6} xs={12} className="text">
          <Typography className="span">Tire sua ideia do papel</Typography>

          <Typography variant="h3" component="h1" className="title">
            Abertura de empresa para <br />
            quem quer <strong>praticidade e</strong> <br />
            <strong>economia</strong> no dia a dia
          </Typography>

          <Spacer size={20} />

          <Box className={classes.description}>
            <GlazedEmoji img={EmojiHand} alt="Emoji apontando para a direita" color="white" />
            <Typography variant="body1">
              Faça o seu cadastro para receber o contato dos nossos especialistas
            </Typography>
          </Box>

          <Box className={classes.button}>
            <StyledButton
              text="Criar conta"
              variant="contained"
              color="secondary"
              width="100%"
              onClick={() => setCreateAccountOpen(true)}
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={12} className={classes.form}>
          <FormCard color="info" hero>
            <Typography variant="button">Comece criando sua conta</Typography>
            <Typography variant="h6" component="p">
              Vamos começar os preparativos para abrir sua empresa
            </Typography>
            <OpenCompanyForm
              name="Abrir Empresa | Lead"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              data={data}
              setData={setData}
            />
          </FormCard>
        </Grid>
      </Grid>

      <CreateAccountDrawer
        open={createAccountOpen}
        setOpen={setCreateAccountOpen}
        process="abrir-empresa"
        step={1}
        openCompanyData={data}
        setOpenCompanyData={setData}
      />
    </HeroBackground>
  );
};

OpenCompanyHero.propTypes = {
  light: PropTypes.bool,
  footer: PropTypes.bool,
  ref: PropTypes.string,
};
OpenCompanyHero.defaultProps = {
  light: false,
  footer: false,
  ref: null,
};

export default OpenCompanyHero;
