import React, { useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import FormCard from '../atoms/FormCard';
import CenteredLoading from '../atoms/CenteredLoading';
import StyledButton from '../atoms/StyledButton';
import ContentWrap from '../atoms/ContentWrap';
import FormBackground from '../atoms/FormBackground';
import OpenCompanyForm from '../organisms/OpenCompanyForm';
import CreateAccountDrawer from '../organisms/CreateAccountDrawer';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '48% auto',
    gap: '20px',

    '@media screen and (max-width: 959px)': {
      gridTemplateColumns: 'auto',
    },
  },
  text: {
    padding: '80px 0 0',
    zIndex: 1,

    '& .MuiTypography-root': {
      color: theme.palette.secondary.contrastText,
      width: '70%',
    },

    '& img': {
      marginBottom: '-3px',
      
      '@media screen and (max-width: 1400px)': {
        marginLeft: '45%',
        transform: 'translateX(-50%)',
      },
    },
    
    '@media screen and (max-width: 959px)': {
      padding: 0,

      '& img': { marginBottom: '-33px' },
      '& .MuiTypography-root': { width: '100%' },
    },
  },
  button: {
    padding: '25px 0',

    '@media screen and (min-width: 959px)': {
      display: 'none',
    },
  },
  form: {
    padding: '40px 0',

    '& .loaded': {
      opacity: '1',
      transition: '0.2s',
    },

    '& .loading': {
      opacity: '0.5',
      transition: '0.2s',
    },

    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  },
}));

const OpenCompanyFormCard = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [createAccountOpen, setCreateAccountOpen] = useState(false);
  const [data, setData] = useState({});

  return (
    <FormBackground color="blue">
      <ContentWrap className={classes.content}>
        <Box className={classes.text}>
          <Typography variant="h4" component="h2">Conube é assim: online, prática e segura!</Typography>

          <Typography variant="h6" component="h3">
            Troque de contador de modo simples e fácil, pagando apenas as taxas do governo!
          </Typography>

          <Box className={classes.button}>
            <StyledButton
              text="Criar conta"
              variant="contained"
              color="white"
              width="100%"
              onClick={() => setCreateAccountOpen(true)}
              style={{ color: '#44A0DB' }}
            />
          </Box>
        </Box>

        <Box className={classes.form}>
          <FormCard color="info">
            <Box className={isLoading ? 'loading' : 'loaded'}>
              <Typography variant="button">Comece criando sua conta</Typography>
              <Typography variant="h6" component="p">
                Vamos começar os preparativos para abrir sua empresa
              </Typography>
              <OpenCompanyForm
                name="Abrir Empresa | Lead Footer"
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                data={data}
                setData={setData}
              />
            </Box>

            {isLoading && <CenteredLoading />}
          </FormCard>
        </Box>
      </ContentWrap>

      <CreateAccountDrawer
        open={createAccountOpen}
        setOpen={setCreateAccountOpen}
        process="abrir-empresa"
        step={1}
        openCompanyData={data}
        setOpenCompanyData={setData}
      />
    </FormBackground>
  );
};

export default OpenCompanyFormCard;
