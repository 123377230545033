import React from 'react';

import { useTheme } from '@material-ui/styles';
import Spacer from '../atoms/Spacer';
import OpenCompanyStepByStep from '../molecules/OpenCompanyStepByStep';
import OpenCompanyCard from '../molecules/OpenCompanyCard';
import FAQList from '../molecules/FAQList';
import ContentWrap from '../atoms/ContentWrap';
import ContentSpacer from '../molecules/ContentSpacer';
import OpenCompanyFormCard from '../molecules/OpenCompanyFormCard';
import CustomerComments from '../organisms/CustomerComments';
import OpenCompanyHero from '../organisms/OpenCompanyHero';
import BlogHighlights from '../organisms/BlogHighlights';
import Functionalities from '../organisms/Functionalities';
import OpenCompanySchema from '../../static/files/OpenCompanySchema.json';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import WhatsAppIcon from '../../static/images/whatsapp.svg.webp';

const useStyles = makeStyles((theme) => ({
  whatsappButton: {
    position: 'fixed',
    bottom: '100px', 
    right: '0px',
    zIndex: '1000',
    padding: '5px',
    width: '60px',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: '#2c3e50',
  
    '@media screen and (max-width: 959px)': {
      width: '50px',
      bottom: '120px', 
      right: '0px',
    },
  },
  whatsappButtonIcon: {
    width: '40px', 
    height: '40px',
    left: '20px', 

    '@media screen and (max-width: 959px)': {
      width: '35px', 
      height: '35px',
    },
  }
}));

const OpenCompany = () => {
  const classes = useStyles();
  const scrollToForm = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const theme = useTheme();

  return (
    <>
      <OpenCompanyHero />

      <ContentWrap>
        <OpenCompanyStepByStep onClick={() => scrollToForm()} color={theme.palette.tnm.main} />
        <Functionalities color="info" />
        <OpenCompanyCard />
        <CustomerComments color="info" />
        <FAQList questions={OpenCompanySchema.mainEntity} color="info" />
      </ContentWrap>

      <ContentSpacer />
      <OpenCompanyFormCard />
      <ContentSpacer />

      <ContentWrap>
        {/* <BlogHighlights /> */}
      </ContentWrap>

      <div 
        className={classes.whatsappButton}
      >
        <a
          href="https://api.whatsapp.com/send/?phone=5511995362311&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
          // className={classes.whatsappButtonIcon}
        >
          <img src={WhatsAppIcon} className={classes.whatsappButtonIcon} alt="WhatsApp"/>
        </a>
      </div>

      <Spacer size={100} />
    </>
  );
};

export default OpenCompany;
