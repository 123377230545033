import React, { useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import CardBackground from '../atoms/CardBackground';
import CenteredLoading from '../atoms/CenteredLoading';
import FormCard from '../atoms/FormCard';
import StyledButton from '../atoms/StyledButton';
import StyledDrawer from '../atoms/StyledDrawer';
import HowMuchOpenCompany from '../organisms/HowMuchOpenCompany';
import ResultOpenCompany from '../organisms/ResultOpenCompany';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0 40px',
    display: 'grid',
    gridTemplateColumns: '48% auto',
    alignItems: 'center',
    gap: '40px',

    '& .loaded': {
      opacity: '1',
      transition: '0.2s',
    },

    '& .loading': {
      opacity: '0.5',
      transition: '0.2s',
    },

    '& .mobile': {
      display: 'none',
    },

    '@media screen and (max-width: 970px)': {
      padding: '15px',
      gridTemplateColumns: 'auto',
      gap: '40px',

      '& .form-card': {
        display: 'none',
      },

      '& .mobile': {
        display: 'block',
      },
    },
  },
  text: {
    '& .MuiTypography-h6, & .MuiTypography-h4': {
      color: theme.palette.secondary.contrastText,
      width: '80%',

      '@media screen and (max-width: 1250px)': {
        width: 'unset',
      },
    },

    '& .MuiTypography-h6': {
      fontWeight: 300,
      marginTop: '25px',
    },
  },
  desktop: {
    display: 'block',

    '@media screen and (max-width: 970px)': {
      display: 'none',
    },
  },
}));

const OpenCompanyCard = () => {
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const handleResultBack = () => {
    setResultData(null);
    setData({});
  };

  return (
    <>
      <CardBackground page="abrir-empresa">
        <Box className={classes.content}>
          <Box className={classes.text}>
            <Typography variant="h4" component="h2">
              Quer saber quanto custa para abrir sua empresa?
            </Typography>

            <Typography variant="h6" component="h3">
              Pague apenas as taxas do
              governo. Os valores variam de acordo com o
              município e com o formato jurídico escolhido.
            </Typography>
          </Box>

          {/* Desktop */}

          {!resultData && (
            <FormCard color="info" className="form-card">
              <Box className={isLoading ? 'loading' : 'loaded'}>
                <Typography variant="button">Calcular custo</Typography>
                <HowMuchOpenCompany
                  name="Abrir Empresa | Calculadora"
                  data={data}
                  setData={setData}
                  setResultData={setResultData}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  color="secondary"
                />
              </Box>
              {isLoading && <CenteredLoading />}
            </FormCard>
          )}

          {resultData && !isLoading && data.city && (
            <ResultOpenCompany
              resultData={resultData}
              setResultData={setResultData}
              goBack={handleResultBack}
              formData={data}
              setFormData={setData}
              className={classes.desktop}
              color="secondary"
            />
          )}

          {/* Mobile */}

          <Box className="mobile">
            <StyledButton
              text="Calcular custo"
              variant="contained"
              color="secondary"
              width="100%"
              onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}
            />

            <StyledDrawer
              open={mobileDrawerOpen}
              setOpen={setMobileDrawerOpen}
              width="84vw"
              borderRadius="0"
            >
              {!resultData && (
                <HowMuchOpenCompany
                  name="Abrir Empresa | Calculadora"
                  data={data}
                  setData={setData}
                  setResultData={setResultData}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  color="secondary"
                />
              )}

              {resultData && !isLoading && data.city && (
                <ResultOpenCompany
                  resultData={resultData}
                  setResultData={setResultData}
                  goBack={handleResultBack}
                  formData={data}
                  setFormData={setData}
                  isOpenCompany
                  color="secondary"
                />
              )}

              {isLoading && <CenteredLoading />}
            </StyledDrawer>
          </Box>
        </Box>
      </CardBackground>
    </>
  );
};

export default OpenCompanyCard;
