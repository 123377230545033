import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import Img from 'gatsby-image';
import StyledButton from '../atoms/StyledButton';
import IconRightArrow from '../atoms/IconRightArrow';
import TutorialStep from '../atoms/TutorialStep';
import StepsBackground from '../atoms/StepsBackground';
import Sticker1 from '../../static/images/mulher-sentada-puff-azul.png';
import Sticker2 from '../../static/images/mulher-cubo-flutuando.png';
import Sticker3 from '../../static/images/homem-notebook-camisa-azul.png';
import ComputerGuy from '../atoms/ComputerGuy';
import WomanSquare from '../atoms/WomanSquare';
import WomanPuff from '../atoms/WomanPuff';
import LazyImage from '../atoms/LazyImage';
import { getImage } from '../functions/useStaticImages';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .title': {
      marginBottom: '20px',

      '& .MuiTypography-root': {
        color: theme.palette.midGrey.main,

        '& span': {
          color: theme.palette.info.highlight,
        },
      },

      '& .MuiTypography-h4': {
        marginBottom: '15px',
      },
    },
  },
  titleDefault: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    '& .MuiTypography-h6': {
      width: '70%',

      '@media screen and (max-width: 1080px)': {
        width: '100%',
      },
    },
  },
  titlePageCompany: {
    width: '45%',

    '@media screen and (max-width: 1080px)': {
      width: '100%',
      textAlign: 'center',
    },
  },
  steps: {
    display: 'grid',
    gridTemplateColumns: '24% auto 32% auto 26%',
    marginBottom: '50px',

    '@media screen and (max-width: 1080px)': {
      gridTemplateColumns: 'auto',
      gap: '20px',
    },
  },
  step: {
    '& .sticker': {
      textAlign: 'center',
      marginBottom: '30px',

      '@media screen and (max-width: 1080px)': {
        display: 'none',
      },
    },
  },
  arrow: {
    textAlign: 'center',
    alignSelf: 'center',

    '& svg path': {
      fill: '#C9C9DF',
    },

    '@media screen and (max-width: 1080px)': {
      display: 'none',
    },
  },
  cardText: {
    '& .MuiTypography-h4': {
      marginBottom: '10px',
    },
  },
  cardButton: {
    display: 'grid',
    justifyContent: 'end',

    '& .MuiButton-root': {
      width: '370px',
      marginBottom: '20px',
      color: theme.palette.info.highlight,
    },
    '& .MuiTypography-body1': {
      width: '370px',
    },

    '@media screen and (max-width: 1080px)': {
      display: 'block',

      '& .MuiButton-root, & .MuiTypography-body1': {
        width: '100%',
      },
    },
  },
  margin: {
    marginTop: '10%',
  },
}));

const OpenCompanyStepByStep = (
  {
    home, onClick, taNaModa, color = '#44A0DB', stepColor = 'info',
  },
) => {
  const classes = useStyles();
  const images = getImage();

  const Sticker1 = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'mulher-sentada-puff-azul.png').fixed;
  const Sticker2 = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'mulher-cubo-flutuando.png').fixed;
  const Sticker3 = images.allImageSharp.nodes.find((n) => n.fixed.originalName === 'homem-notebook-camisa-azul.png').fixed;

  const buttonProps = home ? {
    component: 'a',
    href: '/abrir-empresa/',
  } : { onClick };

  return (
    <Box className={clsx(classes.root, taNaModa ? classes.margin : '')}>
      <Box className={`title ${home ? classes.titleDefault : classes.titlePageCompany}`}>
        <Typography variant="h4" component="h2">
          <span>Abrir sua empresa </span>
          com a Conube é fácil e rápido
        </Typography>

        <Typography variant="h6" component="h3">
          Somos especialistas em abertura de empresas com atividades de prestação
          de serviços e comércio, e nosso passo a passo foi feito para poupar tempo
          e burocracia dos nossos clientes.
        </Typography>
      </Box>

      <Box className={classes.steps}>
        <Box className={classes.step}>
          <Box className="sticker">
            {/* <img src={Sticker1} alt="Pessoa fazendo cadastro" /> */}
            {/* <LazyImage img={Sticker1} alt="Pessoa fazendo cadastro" /> */}
            <LazyImage>
              <Img fixed={Sticker1} alt="Pessoa fazendo cadastro" />
            </LazyImage>
          </Box>

          <TutorialStep
            step={1}
            title="Crie uma conta"
            text="Em menos de 2 minutos você terá seu usuário cadastrado para iniciar o processo."
            color={stepColor}
          />
        </Box>

        <Box className={classes.arrow}>
          <IconRightArrow />
        </Box>

        <Box className={classes.step}>
          <Box className="sticker">
            {/* <img src={Sticker2} alt="Especialista disposto a atender" /> */}
            {/* <LazyImage img={Sticker2} alt="Especialista disposto a atender" /> */}
            <LazyImage>
              <Img fixed={Sticker2} alt="Especialista disposto a atender" />
            </LazyImage>
          </Box>

          <TutorialStep
            step={2}
            title="Fale com nossos especialistas"
            text="Te ajudamos a escolher o formato ideal para sua empresa, de acordo com o seu perfil de empreendedor."
            color={stepColor}
          />
        </Box>

        <Box className={classes.arrow}>
          <IconRightArrow />
        </Box>

        <Box className={classes.step}>
          <Box className="sticker">
            {/* <img src={Sticker3} alt="Pessoa escolhendo um modelo ideal" /> */}
            {/* <LazyImage img={Sticker3} alt="Pessoa escolhendo modelo ideal" /> */}
            <LazyImage>
              <Img fixed={Sticker3} alt="Pessoa escolhendo um modelo ideal" />
            </LazyImage>
          </Box>

          <TutorialStep
            step={3}
            title="Fazemos tudo para você"
            text="Apenas envie seus documentos e informações, que nosso time cuida de todo o resto para você."
            color={stepColor}
          />
        </Box>
      </Box>

      {taNaModa ? (
        ''
      ) : (
        <StepsBackground color="info">
          <Box className={classes.cardText}>
            <Typography variant="h4" component="h3">Vamos nessa!</Typography>

            <Typography variant="body1">
              Em pouco tempo você já começa sua jornada empreendedora, com CNPJ
              em mãos e todo o apoio de nossa equipe de especialistas.
            </Typography>
          </Box>

          <Box className={classes.cardButton}>
            <StyledButton
              text="Abrir empresa"
              variant="contained"
              color="white"
              {...buttonProps}
            />
            <Typography variant="body1">
              Fale conosco e saiba como a Conube pode ajudar a realizar seu sonho de empreender.
            </Typography>
          </Box>
        </StepsBackground>
      )}
    </Box>
  );
};

OpenCompanyStepByStep.propTypes = {
  home: PropTypes.bool,
  onClick: PropTypes.func,
};

OpenCompanyStepByStep.defaultProps = {
  home: false,
  onClick: null,
};

export default OpenCompanyStepByStep;
